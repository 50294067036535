import { AppEvents, PluginExtensionPanelContext, urlUtil } from '@grafana/data';
import { getAppEvents, getDataSourceSrv, config as grafanaConfig, locationService } from '@grafana/runtime';
import { DataQuery } from '@grafana/schema';

import { DATASOURCE_NAME, SUPPORTED_DATASOURCE_TYPES } from 'consts';
import { tryGte } from 'utils/utils.general';

import {
  ExtensionLinkConfig,
  NewPluginExtensionPoints,
  PluginExtensionAlertInstanceContextWrapper,
  PluginExtensionAppO11yOperationContextWrapper,
  PluginExtensionAppO11yServiceContextWrapper,
  PluginExtensionExploreContext,
  PluginExtensionExploreContextWrapper,
  PluginExtensionK8sNamespaceContextWrapper,
  PluginExtensionK8sPodContextWrapper,
  PluginExtensionK8sWorkloadContextWrapper,
  PluginExtensionOnCallAlertGroupContextWrapper,
  PluginExtensionPanelContextWrapper,
  PluginExtensionPoints,
  PluginExtensionSiftPanelContextWrapper,
} from './types';

export function isPanelContextWrapper(wrapper?: any): wrapper is Required<PluginExtensionPanelContextWrapper> {
  return (
    typeof wrapper === 'object' &&
    wrapper.type === PluginExtensionPoints.DashboardPanelMenu &&
    wrapper.context !== undefined
  );
}

export function isExploreContextWrapper(wrapper?: any): wrapper is Required<PluginExtensionExploreContextWrapper> {
  return (
    typeof wrapper === 'object' &&
    wrapper?.type === PluginExtensionPoints.ExploreToolbarAction &&
    wrapper.context !== undefined
  );
}

export function isAlertingContextWrapper(
  wrapper?: any
): wrapper is Required<PluginExtensionAlertInstanceContextWrapper> {
  return (
    typeof wrapper === 'object' &&
    wrapper?.type === NewPluginExtensionPoints.AlertInstanceAction &&
    wrapper.context !== undefined
  );
}

export function isOnCallAlertGroupContextWrapper(
  wrapper?: any
): wrapper is Required<PluginExtensionOnCallAlertGroupContextWrapper> {
  return (
    typeof wrapper === 'object' &&
    (wrapper?.type === NewPluginExtensionPoints.OnCallAlertGroupAction ||
      wrapper?.type === NewPluginExtensionPoints.IRMOnCallAlertGroupAction) &&
    wrapper.context !== undefined
  );
}

export function isCommandPaletteContextWrapper(
  wrapper?: any
): wrapper is Required<PluginExtensionOnCallAlertGroupContextWrapper> {
  return (
    typeof wrapper === 'object' &&
    wrapper?.type === NewPluginExtensionPoints.CommandPalette &&
    wrapper.context !== undefined
  );
}

export function isK8sClusterContextWrapper(
  wrapper?: any
): wrapper is Required<PluginExtensionK8sNamespaceContextWrapper> {
  return (
    typeof wrapper === 'object' &&
    wrapper?.type === NewPluginExtensionPoints.K8sClusterAction &&
    wrapper.context !== undefined
  );
}

export function isK8sNamespaceContextWrapper(
  wrapper?: any
): wrapper is Required<PluginExtensionK8sNamespaceContextWrapper> {
  return (
    typeof wrapper === 'object' &&
    wrapper?.type === NewPluginExtensionPoints.K8sNamespaceAction &&
    wrapper.context !== undefined
  );
}

export function isK8sWorkloadContextWrapper(
  wrapper?: any
): wrapper is Required<PluginExtensionK8sWorkloadContextWrapper> {
  return (
    typeof wrapper === 'object' &&
    wrapper?.type === NewPluginExtensionPoints.K8sWorkloadAction &&
    wrapper.context !== undefined
  );
}

export function isK8sPodContextWrapper(wrapper?: any): wrapper is Required<PluginExtensionK8sPodContextWrapper> {
  return (
    typeof wrapper === 'object' &&
    wrapper?.type === NewPluginExtensionPoints.K8sPodAction &&
    wrapper.context !== undefined
  );
}

export function isSiftPanelContextWrapper(wrapper?: any): wrapper is Required<PluginExtensionSiftPanelContextWrapper> {
  return (
    typeof wrapper === 'object' &&
    wrapper?.type === NewPluginExtensionPoints.SiftPanelRunInvestigation &&
    wrapper.context !== undefined
  );
}

export function isAppO11yServiceContextWrapper(
  wrapper?: any
): wrapper is Required<PluginExtensionAppO11yServiceContextWrapper> {
  return (
    typeof wrapper === 'object' &&
    wrapper?.type === NewPluginExtensionPoints.AppO11yServiceAction &&
    wrapper.context !== undefined
  );
}
export function isAppO11yOperationContextWrapper(
  wrapper?: any
): wrapper is Required<PluginExtensionAppO11yOperationContextWrapper> {
  return (
    typeof wrapper === 'object' &&
    wrapper?.type === NewPluginExtensionPoints.AppO11yOperationAction &&
    wrapper.context !== undefined
  );
}

// The type of function returned by configureSupportForLink. This fulfils the requirements of
// the `configure` property of `PluginExtensionLinkConfig`.
type ConfigureFunc = (context?: PluginExtensionPanelContext | PluginExtensionExploreContext) =>
  | Partial<{
      path: string;
    }>
  | undefined;

// Given a path, returns a function that checks whether we should add an extension link for the
// context passed in by Grafana. The path is used to render the link.
export function configureSupportForLink(
  path: string,
  type: PluginExtensionPoints.DashboardPanelMenu | PluginExtensionPoints.ExploreToolbarAction
): ConfigureFunc {
  return (context) => {
    const wrapper = { type, context };
    if (
      isPanelContextWrapper(wrapper) &&
      wrapper.context?.pluginId !== 'timeseries' &&
      wrapper.context?.pluginId !== 'graph'
    ) {
      return undefined;
    }

    const [query] = context?.targets ?? [];
    if (!query || !query.datasource?.uid) {
      return undefined;
    }

    const datasourceSettings = getDataSourceSrv().getInstanceSettings(query.datasource);
    if (!datasourceSettings) {
      return undefined;
    }

    if (!SUPPORTED_DATASOURCE_TYPES.includes(datasourceSettings.type)) {
      return undefined;
    }

    if (datasourceSettings.name === DATASOURCE_NAME) {
      return undefined;
    }

    // Override the path and params to include the query and datasource.
    return {
      path: urlUtil.renderUrl(path, {
        ds: query.datasource.uid,
        query_params: JSON.stringify(query),
      }),
    };
  };
}

type NavigateOptions = {
  path: string;
  context?: PluginExtensionPanelContext;
  errorMessage: string;
};

export async function navigateToPathWithParams(options: NavigateOptions): Promise<void> {
  // It is important that we catch potential errors in this async function
  try {
    const { context, path } = options;
    // @ts-ignore - we need to updated to the version running in main.
    // TODO: remove this once we have updated to @grafana/data v10.
    // Since we have a default value for scopedVars this will still work in Grafana 9.5.
    const { scopedVars = {}, targets } = context;
    const [query] = targets;
    const ds = await getDataSourceSrv().get(query.datasource);

    if (!ds.interpolateVariablesInQueries) {
      return locationService.push({
        pathname: path,
        search: urlUtil.toUrlParams({
          ds: query.datasource.uid,
          query_params: JSON.stringify(query),
        }),
      });
    }

    const [interpolated] = ds.interpolateVariablesInQueries([query], scopedVars);
    return locationService.push({
      pathname: path,
      search: urlUtil.toUrlParams({
        ds: query.datasource.uid,
        query_params: JSON.stringify(interpolated),
      }),
    });
  } catch (_error) {
    getAppEvents().publish({
      type: AppEvents.alertError.name,
      payload: [options.errorMessage],
    });
  }
}

export function createLinkConfig(config: ExtensionLinkConfig): ExtensionLinkConfig {
  const { category, icon, ...rest } = config;
  // Grafana versions prior to 10.0.4 would not show the extension links if any
  // unknown fields appeared in the extension config, and some of these fields
  // were added later, so we only try to set them if Grafana is newer than 10.0.4.
  if (tryGte(grafanaConfig.buildInfo.version, '10.0.4')) {
    return {
      ...rest,
      category,
      // Ignore Typescript errors here: @grafana/data>10.2.0 _does_ include the
      // 'gf-ml' icon, and is bundled in cloud, but we're not building against it yet.
      // See https://github.com/grafana/grafana/pull/73813.
      // If a customer is pinned to <10.2.0, they'll just see a blank icon, the same
      // as if we hadn't overridden this.
      // @ts-ignore
      icon,
    };
  }

  return rest;
}

export function getQueryCustomParams(query: DataQuery): DataQuery {
  // We only keep the custom values not the default ones.
  // eslint-disable-next-line
  const { datasource, key, queryType, hide, ...custom } = query;

  if ('instant' in custom) {
    // If instant is being passed for explore queries we exclude that one.
    // eslint-disable-next-line
    const { instant, ...rest } = custom;
    return rest;
  }

  return custom;
}
