/**
 * Cleans array data and validates it to be suitable for Prophet.
 *
 * Replaces any null-ish entries with NaN.
 *
 * Return null & an error message if:
 *  - array has less than 100 non-NaN numbers
 *  - array contains all NaNs
 *  - array consists of a single number repeated.
 * otherwise the clean array returned as a Float64Array
 */
export function cleanAndValidateDataForProphet(y: Array<number | null | undefined>): [Float64Array] | [null, string] {
  let nonNanCount = 0;
  let firstNumber: number | null = null;
  let onlyOneNumberFound = true;

  for (let i = 0; i < y.length; i++) {
    if (y[i] == null) {
      y[i] = NaN;
    }

    if (!isNaN(y[i] as number)) {
      nonNanCount++;
      if (firstNumber === null) {
        firstNumber = y[i] as number;
      } else if (y[i] !== firstNumber) {
        onlyOneNumberFound = false;
      }
    }
  }

  if (nonNanCount === 0) {
    return [null, 'Series contains only NaNs, cannot forecast with no numeric data'];
  }
  if (nonNanCount < 100) {
    return [null, 'Series has less than 100 valid datapoints, not enough data to forecast'];
  }
  if (onlyOneNumberFound) {
    return [null, `Series contains only one unique value "${firstNumber}", data not suitable for forecasting`];
  }

  return [new Float64Array(y as number[])];
}
