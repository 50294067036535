import { AppPlugin } from '@grafana/data';

import { isFunction } from 'lodash';

import { withSuspense } from 'utils/utils.lazy';

import * as alerting from './alerting';
import * as ml from './ml';
import * as sift from './sift';
import { ExtensionComponentConfig, ExtensionLinkConfig } from './types';

const linkConfigs: ExtensionLinkConfig[] = [
  // Forecast and outlier detection links
  ...ml.extensionLinks,

  // Dashboard panel menu
  ...(sift.panelExtensionLinks as ExtensionLinkConfig[]),

  // Explore add button
  ...(sift.exploreExtensionLinks as ExtensionLinkConfig[]),

  // Alerting
  ...(sift.alertingExtensionLinks as ExtensionLinkConfig[]),

  // Command palette action
  ...(sift.commandPaletteExtensionLinks as ExtensionLinkConfig[]),

  // On call alert group - to be removed after IRM release
  ...(sift.onCallAlertGroupExtensionLinks as ExtensionLinkConfig[]),

  // IRM alert group
  ...(sift.IRMOnCallAlertGroupExtensionLinks as ExtensionLinkConfig[]),

  // Kubernetes app
  ...(sift.k8sExtensionLinks as ExtensionLinkConfig[]),

  // Sift panel
  sift.siftPanelLink as ExtensionLinkConfig,

  // Application Observability app
  ...(sift.appO11yExtensionLinks as ExtensionLinkConfig[]),
];

// Returns true if the component is a lazy component (i.e. it has
// been loaded with `React.lazy()`).
function isLazy(component: any): boolean {
  return component?.type?.name === 'Lazy';
}

// Wraps a plugin extension component in a Suspense component if it's a lazy component.
// Otherwise, returns the original extension.
// This is needed because Grafana checks at load-time whether a component is a
// proper React component, which lazy components are not (they're promises which
// resolve to components).
function makeComponentExtension(extension: ExtensionComponentConfig): ExtensionComponentConfig {
  if (!isLazy(extension.component)) {
    return extension;
  }
  return {
    ...extension,
    component: withSuspense(extension.component),
  };
}

const componentExtensions: ExtensionComponentConfig[] = [
  ...(sift.siftAnalysisModals as ExtensionComponentConfig[]),
  sift.siftRunModal as ExtensionComponentConfig,
  sift.IRMSiftRunModal as ExtensionComponentConfig,
  alerting.alertingHomePageCallout,
].map(makeComponentExtension);

export function configureAppExtensions(app: AppPlugin): AppPlugin {
  if (isFunction(app.addComponent)) {
    app = componentExtensions.reduce((app, component) => {
      return app.addComponent(component);
    }, app);
  }

  if (isFunction(app.addLink)) {
    app = linkConfigs.reduce((app, link) => {
      return app.addLink(link);
    }, app);
  }

  return app;
}
