import { createContext, useContext } from 'react';

import { AppPluginMeta } from '@grafana/data';
import { config } from '@grafana/runtime';

import { MlAppSettings } from 'types';

export const PluginMetaContext = createContext<AppPluginMeta<MlAppSettings> | null>(null);

export function usePluginMeta(): AppPluginMeta<MlAppSettings> | null {
  return useContext(PluginMetaContext);
}

export function getIrmIfPresentOrOnCallPluginId() {
  return 'grafana-irm-app' in config.apps ? 'grafana-irm-app' : 'grafana-oncall-app';
}
